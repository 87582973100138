<template>
<div id="page" class="equipment-order-manage-page">
  <div class="cpmi-statistical-part" v-loading="countLoading">
    <statistical :data="statisticalData" />
  </div>
  <div class="cpmi-list-part">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="keyword">
        <el-input v-model="form.keyword" placeholder="姓名/联系方式" clearable>
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="发货状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择" size="small">
          <el-option v-for="item in statusOption" :key="item.id" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" v-loading="tabelLoading">
      <el-table-column prop="txt_order_number" label="申领单号" align="center" />
      <el-table-column prop="txt_contact_name" label="姓名" align="center" />
      <el-table-column prop="txt_contact_phone" label="联系方式" align="center" />
      <el-table-column prop="txt_contact_address" label="配送地址" :show-overflow-tooltip="true" align="center"></el-table-column>
      <el-table-column prop="dec_order_amount" label="订单金额（元）" align="center" />
      <el-table-column prop="txt_commodities" label="申领详情" :show-overflow-tooltip="true" align="center" />
      <el-table-column prop="txt_express_number" label="运单号" align="center">
        <template #default="scope">{{scope.row.txt_express_number||"- -"}}</template>
      </el-table-column>
      <el-table-column prop="dt_order" label="确认收款时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center" class-name="table-option" v-if="rowShow">
        <template #default="scope">
          <div class="table-icon" @click="delivery(scope.row)">
            <i class="iconfont icon-daifahuo "></i> 发货
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <pagination :total="total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" /> -->
    <div class="cpmilp-page-btns row-center-end">
      <el-button :disabled="form.page==1" @click="form.page-=1;getList()">上一页</el-button>
      <el-button @click="form.page+=1;getList()">下一页</el-button>
    </div>
  </div>
</div>
</template>

<script>
// 订单管理 - 设备申领订单
import { onMounted, reactive, toRefs, ref } from 'vue';
import { Statistical } from "components";
import { FormInstance, ElMessageBox, ElMessage } from 'element-plus';
import { getEqOrderForList, deliveryOrder } from "api/apis.js";
export default {
  components: {
    Statistical,
    // Pagination,
  },
  setup() {
    const state = reactive({
      formRef: ref(FormInstance),
      statisticalData: [
        { key: "cnt_allapplications", value: 0, end: "单", zn: "未发货订单量", eg: "Quantity of order" }
      ],
      rowShow: false,
      countLoading: false,
      tabelLoading: false,
      statusOption: [
        // { id: 2, value: 1, label: "已发货" },
        // { id: 3, value: 0, label: "未发货" }
        { id: 3, value: 3, label: "已发货" },
        { id: 2, value: 2, label: "出库中" },
        { id: 1, value: 1, label: "未发货" }
      ],
      form: {
        keyword: "",
        status: 1,
        page: 1,
        limit: 10,
      },
      total: 0,
      tableData: []
    });

    onMounted(() => {
      getList();
      getQuantityOrder();
    });

    // 获取订单列表
    const getList = () => {
      state.tabelLoading = true;
      // 遍历，删除没有值的键
      Object.keys(state.form).forEach(item => {
        if ((state.form[item] || "") == "") {
          delete state.form[item];
        }
      })
      getEqOrderForList(state.form).then(response => {
        if (response.code == 200) {
          state.tableData = response.data;
          if (state.form.status == 1) {
            state.rowShow = true;
          } else {
            state.rowShow = false;
          }
        } else {
          state.tableData = [];
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.tabelLoading = false;
      })
    };

    // 获取未发货订单量
    const getQuantityOrder = () => {
      state.countLoading = true;
      getEqOrderForList({ status: 1 }).then(response => {
        if (response.code == 200) {
          if (response.data != null) {
            state.statisticalData[0].value = response.data.length;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.countLoading = false;
      })
    };

    // 发货
    const delivery = (item) => {
      state.tabelLoading = true;
      ElMessageBox.confirm(
        `请确认是否将订单【${item.txt_order_number}】发货，发货后不可取消`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        // 确认发货
        deliveryOrder({ order_id: item.t_level3_order_id, agent_id: item.t_order_agent_id }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            getList();
            getQuantityOrder();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.tabelLoading = false;
        })
      }).catch(() => {
        console.log("取消操作");
        state.tabelLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.formRef.resetFields();
      getList();
    };

    return {
      getList,
      reset,
      delivery,
      getQuantityOrder,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment-order-manage-page {
  .cpmi-list-part {
    margin-top: 24px;
    height: calc(100% - 150px);

    .el-table {
      height: calc(100% - 100px);
    }

    .cpmilp-page-btns {
      margin-top: 6px;
    }
  }
}
</style>
